import React, { Component } from 'react';

import {
  Button, Form, Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { clearSubSection } from 'dmpconnectjsapp-base/actions';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { authenticationTypes, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  formatSetDMPAccessModeParams,
  formatUpdateUserDmpAccessAuthorizationParams,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { getConfigurationValue, isAirActive } from 'dmpconnectjsapp-base/helpers/accessors';
import { isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { push } from 'connected-react-router';
import {
  getAction, updateUserDmpAccessAuthorizationWithSecretConnection,
} from '../../dmpconnect/actions';
import { accessModes, userAuthorizationAction, userAuthorizationStatuses } from '../../dmpconnect/constants';
import {
  getDirectAuthenticationStatus, isPatientMinor,
} from '../../dmpconnect/helpers/directAuthenticationDMPStatus';
import checkAccessRights from '../AccessRights/CheckAccessRights';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import TitleTooltip from '../Common/TitleTooltip';
import env from '../../envVariables';
import CustomCheckbox from '../Common/Form/Input/CustomCheckbox';
import Alert from '../Common/Message/Alert';
import SwitchCheckbox from '../Common/Form/Input/SwitchCheckbox';

export const displayModes = {
  FULL: 'FULL',
  EMERGENCY: 'EMERGENCY',
  NORMAL: 'NORMAL',
};

const initialState = displayMode => ({
  show: false,
  reason: '',
  // authorizationType:
  //   displayMode === displayModes.EMERGENCY
  //     ? 'emergency_access'
  //     : 'normal_access',
  authorizationType: null,
  emergencyAccessMode: accessModes.Centre15,
});

class DmpAuthorization extends Component {
  constructor(props) {
    super(props);
    const { standalone, displayMode } = props;
    this.state = initialState(displayMode);
    this.state.show = standalone;
  }

  handleClose() {
    const { standalone, dispatch, onClose } = this.props;
    if (standalone) {
      if (onClose) {
        onClose();
      } else {
        dispatch(push('/home/'));
      }
    } else {
      this.setState({ show: false });
    }
  }

  handleShow() {
    const { displayMode, dispatch, ins } = this.props;
    // dispatch(clearSection(apiSections.DMP_ACCESS_MODE_SECTION));
    dispatch(clearSubSection(apiSections.USER_DMP_ACCESS_AUTHORIZATION_SECTION, ins));
    this.setState({ ...initialState(displayMode), show: true });
  }

  handleChange(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const newState = { [event.target.name]: value };

    if (event.target.name === 'emergencyAccessMode') {
      newState.authorizationType = 'emergency_access';
    }
    if (event.target.name === 'authorizationType') {
      newState.emergencyAccessMode = null;
    }
    this.setState(newState);
  }

  updateAuthorization(secretConnection) {
    const {
      authorizationType,
      reason,
      emergencyAccessMode,
    } = this.state;
    const {
      ins,
      dispatch,
      accessRights: {
        authenticationType, psId, esId, airOnly,
        [transactions.AUTHORIZED_DMPS]: auth,
      },
      directAuthenticationStatus: {
        ExistingTestAnswer: {
          psAuthorization,
          esAuthorization,
        } = {},
      } = {},
    } = this.props;
    // this.setState({ show: false });
    if (
      authorizationType === 'normal_access'
    ) {
      const actions = [];
      if (
        !psAuthorization
        || (psAuthorization && psAuthorization !== userAuthorizationStatuses.AuthorizationExist)
      ) {
        actions.push(getAction(
          commands.updateUserDmpAccessAuthorization,
          apiSections.USER_DMP_ACCESS_AUTHORIZATION_SECTION,
          formatUpdateUserDmpAccessAuthorizationParams(
            ins,
            userAuthorizationAction.AddAuthorization,
            0,
          ),
          {
            subSection: `${ins}/${psId}`,
            contextParams: { ins, performer: psId, authorizedDmpsTransaction: auth },
          },
        ));
      }

      if (
        !airOnly
        && authenticationType === authenticationTypes.AIR
        && esAuthorization !== userAuthorizationStatuses.AuthorizationExist
      ) {
        actions.push(getAction(
          commands.updateUserDmpAccessAuthorization,
          apiSections.USER_DMP_ACCESS_AUTHORIZATION_SECTION,
          formatUpdateUserDmpAccessAuthorizationParams(
            ins,
            userAuthorizationAction.AddAuthorization,
            0,
          ),
          {
            subSection: `${ins}/${esId}`,
            contextParams: { ins, performer: esId },
            forceEs: true,
          },
        ));
      }

      if (secretConnection !== undefined) {
        dispatch(updateUserDmpAccessAuthorizationWithSecretConnection(actions, ins, secretConnection));
      } else {
        actions.forEach(action => dispatch(action));
      }
    } else {
      const dmpAccessModeParams = formatSetDMPAccessModeParams(emergencyAccessMode, reason);
      dispatch(getAction(
        commands.setDMPAccessMode,
        apiSections.DMP_ACCESS_MODE_SECTION,
        dmpAccessModeParams,
        {
          contextParams: {
            ...dmpAccessModeParams,
            ins,
          },
        },
      ));
    }
  }

  render() {
    const {
      show, authorizationType, emergencyAccessMode, reason,
    } = this.state;
    const {
      standalone, displayMode, accessRights,
      loading,
      directAuthenticationStatus,
      minimumMinorAge,
      debugMinorAge,
      forceSecretConnexion,
      secretConnection,
      isMobile,
    } = this.props;
    const { allowedAccessModes } = accessRights;

    const isMinor = forceSecretConnexion || isPatientMinor(
      directAuthenticationStatus,
      minimumMinorAge,
      debugMinorAge,
    );

    const isValid = (
      !!authorizationType
      && (
        Number(emergencyAccessMode) !== accessModes.BrisDeGlace
        || (reason !== '' && Number(emergencyAccessMode) === accessModes.BrisDeGlace)
      )
    );

    if (
      displayMode === displayModes.EMERGENCY
      && (
        Number(env.REACT_APP_DISABLE_EMERGENCY_ACCESS) === 1
        || !allowedAccessModes.some(access => [accessModes.BrisDeGlace, accessModes.Centre15].includes(access))
      )
    ) return false;

    const centre15Active = authorizationType === 'emergency_access'
      && allowedAccessModes.includes(accessModes.Centre15)
      && Number(emergencyAccessMode) === accessModes.Centre15;

    const bdgActive = authorizationType === 'emergency_access'
      && allowedAccessModes.includes(accessModes.BrisDeGlace)
      && Number(emergencyAccessMode) === accessModes.BrisDeGlace;

    return (
      <>
        {!standalone && (
          <>
            {(displayMode === displayModes.FULL || displayMode === displayModes.NORMAL) && (
              <TitleTooltip
                id="add-access"
                text="Ajouter une autorisation d'accès"
              >
                <Button
                  id="addAuthButton"
                  variant="outline-no-access"
                  size="sm"
                  onClick={() => this.handleShow()}
                >
                  <i className="ic-dmp-no-autorisation" />
                  DMP
                </Button>
              </TitleTooltip>
            )}
            {displayMode === displayModes.EMERGENCY && (
              <TitleTooltip
                id="emergency-access"
                text="Accéder en mode urgence"
              >
                <Button
                  id="emergencyAccessButton"
                  variant="outline-warning"
                  onClick={() => this.handleShow()}
                >
                  <i className="ic-dmp-no-autorisation" />
                  Urgence
                </Button>
              </TitleTooltip>
            )}
          </>
        )}
        <Modal
          id="authModal"
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
          show={show}
          onHide={() => this.handleClose()}
        >
          <Modal.Header closeButton={!standalone}>
            <Modal.Title as="h5">Accéder au DMP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(displayMode === displayModes.FULL || displayMode === displayModes.NORMAL) && (
              <>
                <div className="typography-default-text-title">Ajouter une autorisation</div>
                <div className="toggle-checkbox-container mt-2">
                  <div className={`toggle-checkbox ${!isMobile ? 'border' : ''} ${authorizationType === 'normal_access' ? 'active' : ''}`}>
                    {isMobile ? (
                      <SwitchCheckbox
                        id="dmp-add-access"
                        onChange={(event) => {
                          const { target: { checked, name } } = event;
                          let value;
                          if (checked) {
                            value = 'normal_access';

                            this.handleChange({ target: { type: 'radio', value, name } });
                          }
                        }}
                        name="authorizationType"
                        checked={authorizationType === 'normal_access'}
                        showLabel
                        onLabel="Le patient (ou son représentant légal) m'a autorisé à accéder à son DMP."
                        offLabel="Le patient (ou son représentant légal) m'a autorisé à accéder à son DMP."
                      />
                    ) : (
                      <CustomCheckbox
                        type="radio"
                        id="dmp-add-access"
                        checked={authorizationType === 'normal_access'}
                        label="Le patient (ou son représentant légal) m'a autorisé à accéder à son DMP."
                        value="normal_access"
                        name="authorizationType"
                        onChange={event => this.handleChange(event)}
                      />
                    )}
                  </div>
                </div>
                <Alert type="info" className="mt-3">
                  Avec le consentement patient, le logiciel pourra effectuer des requêtes de recherches de
                  documents au nom de l'utilisateur authentifié sur le DMP et permettra de les consulter sur
                  action manuelle.
                  <br />
                  Ces interactions sont tracées avec l'identifiant national du professionnel
                  authentifié et <strong>le patient sera notifié immédiatement de ces interactions.
                  <br />
                  Toutes
                  recherches et/ou consultations de ma part d'un document pour lequel le patient ne
                  m'a pas autorisé et/ou je ne fais pas partie de l'équipe de soins m'expose à des
                  poursuites.</strong>
                </Alert>
                {authorizationType === 'normal_access' && isMinor === true && secretConnection !== false && (
                <div className="ml-4 mt-3">
                  Le patient est mineur : vous pouvez accéder de façon confidentielle à son DMP.

                  <Alert type="info" className="mt-3">
                    La connexion secrète permet de bloquer l&apos;envoi d&apos;une notification de premier accès et la consulation de la trace d&apos;accès au représentants légaux afin de préserver le secret du mineur titulaire du DMP.
                    <br />
                    <br />
                    Cette modalité de connexion doit être exclusivement réservée au cas de préservation de secret des mineurs encadré dans la réglementation en vigueur.
                    <br />
                    <br />
                    Le médecin traitant du mineur peut consulter à tout moment l&apos;historique d&apos;accès, même en cas de connexion secrète.
                  </Alert>
                </div>
                )}
              </>
            )}
            {[accessModes.BrisDeGlace, accessModes.Centre15].some(access => allowedAccessModes.includes(access)) && (
              <>
                <div className="typography-default-text-title mt-3 mb-2">En mode bris de glace</div>
                <div className="toggle-checkbox-container">
                  <div className={`toggle-checkbox  ${!isMobile ? 'border' : ''} ${centre15Active ? 'active' : ''}`}>
                    {isMobile ? (
                      <SwitchCheckbox
                        id="dmp-access-regu"
                        onChange={(event) => {
                          const { target: { checked, name } } = event;
                          let value;
                          if (checked) {
                            value = accessModes.Centre15;

                            this.handleChange({ target: { type: 'radio', value, name } });
                          }
                        }}
                        name="emergencyAccessMode"
                        checked={centre15Active}
                        showLabel
                        onLabel="Régulation Samu / Centre 15"
                        offLabel="Régulation Samu / Centre 15"
                        disabled={!allowedAccessModes.includes(accessModes.Centre15)}
                      />
                    ) : (
                      <CustomCheckbox
                        type="radio"
                        id="dmp-access-regu"
                        label="Régulation Samu / Centre 15"
                        value={accessModes.Centre15}
                        checked={centre15Active}
                        name="emergencyAccessMode"
                        onChange={event => this.handleChange(event)}
                        disabled={!allowedAccessModes.includes(accessModes.Centre15)}
                      />
                    )}
                  </div>
                  <div className={`toggle-checkbox  ${!isMobile ? 'border' : ''} ${bdgActive ? 'active' : ''}`}>
                    {isMobile ? (
                      <SwitchCheckbox
                        id="dmp-access-bdg"
                        onChange={(event) => {
                          const { target: { checked, name } } = event;
                          let value;
                          if (checked) {
                            value = accessModes.BrisDeGlace;

                            this.handleChange({ target: { type: 'radio', value, name } });
                          }
                        }}
                        name="emergencyAccessMode"
                        checked={bdgActive}
                        showLabel
                        onLabel="Risque immédiat pour la santé du patient"
                        offLabel="Risque immédiat pour la santé du patient"
                        disabled={!allowedAccessModes.includes(accessModes.BrisDeGlace)}
                      />
                    ) : (
                      <CustomCheckbox
                        type="radio"
                        id="dmp-access-bdg"
                        label="Risque immédiat pour la santé du patient"
                        value={accessModes.BrisDeGlace}
                        checked={bdgActive}
                        name="emergencyAccessMode"
                        onChange={event => this.handleChange(event)}
                        disabled={!allowedAccessModes.includes(accessModes.BrisDeGlace)}
                      />
                    )}
                  </div>
                </div>

                {
                  authorizationType === 'emergency_access'
                  && allowedAccessModes.includes(accessModes.BrisDeGlace)
                  && Number(emergencyAccessMode) === accessModes.BrisDeGlace
                  && (
                    <Form.Group controlId="reason" className="mt-3">
                      <Form.Label>Motif de l&#39;accès en urgence au DMP</Form.Label>
                      <Form.Control
                        name="reason"
                        value={reason}
                        as="textarea"
                        rows="3"
                        required
                        onChange={event => this.handleChange(event)}
                      />
                    </Form.Group>
                  )
                }
              </>
            )}

          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button
              size="sm"
              variant={isMobile ? 'borderless-primary' : 'outline-secondary'}
              onClick={() => this.handleClose()}
            >
              Annuler
            </Button>
            <div>
              {authorizationType === 'normal_access' && isMinor === true && secretConnection !== false && (
                <ButtonWithLoader
                  size="sm"
                  variant="danger"
                  className="mr-2"
                  id="authModalSubmit"
                  label="Connexion secrète"
                  onClick={() => this.updateAuthorization(true)}
                  loading={loading}
                  disabled={!isValid}
                />
              )}
              {(!secretConnection || authorizationType !== 'normal_access') && (
              <ButtonWithLoader
                size="sm"
                id="authModalSubmit"
                label={authorizationType === 'normal_access' && isMinor === true ? 'Connexion normale' : 'Accéder au DMP'}
                onClick={() => this.updateAuthorization(false)}
                loading={loading}
                disabled={!isValid}
              />
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { ins, accessRights: { psId, esId } } = props;
  const {
    dmpconnect: {
      [apiSections.DMP_ACCESS_MODE_SECTION]: dmpAccessMode,
      [apiSections.USER_DMP_ACCESS_AUTHORIZATION_SECTION]: {
        [`${ins}/${psId}`]: psUserDmpAccessAuthorization,
        [`${ins}/${esId}`]: esUserDmpAccessAuthorization,
      },
      secretConnection,
      [apiSections.CONFIDENTIALITY_LEVEL_SECTION]: {
        [props.ins]: confidentialityLevelSection,
      },
    },
    userPreferences,
    dmpconnectConnectorConfig: {
      debugMinorAge,
    },
    dmpconnectUser: {
      forceSecretConnexion: {
        [ins]: forceSecretConnexion,
      },
    },
  } = state;
  return {
    psUserDmpAccessAuthorization,
    directAuthenticationStatus: getDirectAuthenticationStatus(state, ins),
    air: isAirActive(state),
    loading: isLoading(psUserDmpAccessAuthorization) || isLoading(esUserDmpAccessAuthorization) || isLoading(dmpAccessMode),
    minimumMinorAge: Number(getConfigurationValue('minimumMinorAge', userPreferences)),
    debugMinorAge: debugMinorAge !== '' ? Number(debugMinorAge) : null,
    forceSecretConnexion,
    secretConnection: isReady(confidentialityLevelSection) ? secretConnection === 1 : undefined,
  };
}

DmpAuthorization.defaultProps = {
  standalone: false,
  displayMode: displayModes.FULL,
  directAuthenticationStatus: {},
  loading: false,
  forceSecretConnexion: false,
  secretConnection: undefined,
  debugMinorAge: null,
  onClose: undefined,
  isMobile: false,
};

DmpAuthorization.propTypes = {
  ins: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  standalone: PropTypes.bool,
  displayMode: PropTypes.string,
  directAuthenticationStatus: PropTypes.object,
  accessRights: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  minimumMinorAge: PropTypes.number.isRequired,
  debugMinorAge: PropTypes.number,
  forceSecretConnexion: PropTypes.bool,
  secretConnection: PropTypes.bool,
  onClose: PropTypes.func,
  isMobile: PropTypes.bool,
};

const ConnectedDmpAuthorization = connect(mapStateToProps)(DmpAuthorization);

export default checkAccessRights(ConnectedDmpAuthorization, transactions.AUTHORIZATION_ADD);
